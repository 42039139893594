@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500&display=swap");

body {
  margin: 0px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.MuiFilledInput-root {
  background: none !important;
}

.MuiFilledInput-root:hover {
  background: none !important;
}

.MuiFilledInput-root:focus {
  background: none !important;
}

.Mui-focused {
  background: none !important;
}

*:focus {
  outline: none;
}
* {
  font-family: "Poppins", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: #606060;
}

*::-webkit-scrollbar {
  overflow: visible;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent !important;
  margin-left: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #606060;
  border-radius: 25px;
  border: 4px solid #606060;
}